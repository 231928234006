import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'

import { vw, colors } from '../utilities/helpers'
import SEO from '../utilities/seo'

import Block from '../components/block'
import Footer from '../components/footer'
import Text from '../components/text'

const Preface = styled.section`
    margin-left: ${vw(3)};
    margin-top: ${vw(3.5)};
    width: ${vw(17)};
    position: absolute;

    h1{
        position: relative; 
        padding-bottom: ${vw(0.5)};
    }

    p{
        padding-bottom: ${vw(0.5)};
    }

    >section {
        width: ${vw(7)};
        margin-left: ${vw(2)};
    }
    
    a{
        color: ${colors.green};
        display: block;
        padding: ${vw(0.24)} 0;
        border-bottom: solid 1px ${colors.green};
        margin-left: ${vw(1)};
    }
`


export default () => {

  return (
    <>
    <main>
      <SEO title="404 Page not found" />
      <Block>
        <Preface>
          <section>
            <h1><Text xlarge>Ding it!</Text></h1>
            <p>Sorry, the requested page can not be found! What could you be looking for?</p>
            <div>
              <Link to="/program">Program</Link>
              <Link to="/speakers">Speakers</Link>
              <Link to="/exhibition">Exhibition</Link>
              <Link to="/media">Media</Link>
              <Link to="/register">Register</Link>
            </div>
          </section>
        </Preface>
      </Block>
    </main>
    <Footer />
    </>
  )
}
